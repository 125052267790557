import React from "react";
import Header from "../components/Header";

function Cyberlinks() {
  return (
    <div className="App">
      <div className="App-content">
        <Header />
        Cyberlinks
        <ul>
          <li>
            <a href="https://github.com/andreakiro">github/andreakiro</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/pinto-andrea/">
              linkedin/pinto-andrea
            </a>
          </li>
        </ul>
        Emails
        <ul>
          <li>
            MIT email address <a href="mailto:pintoa@mit.edu">pintoa@mit.edu</a>{" "}
            (preferred)
          </li>
          <li>
            ETHZ email address{" "}
            <a href="mailto:pintoa@ethz.ch">pintoa@ethz.ch</a> (preferred)
          </li>
          <li>
            EPFL email address{" "}
            <a href="mailto:andrea.pinto@alumni.epfl.ch">
              andrea.pinto@alumni.epfl.ch
            </a>
          </li>
          <li>
            Fulbright email address{" "}
            <a href="mailto:andrea.pinto@fulbrightmail.org">
              andrea.pinto@fulbrightmail.org
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Cyberlinks;

import React from "react";
import Header from "../components/Header";

function Interests() {
  return (
    <div className="App">
      <div className="App-content">
        <Header />
        Research interests. Broad machine learning but mainly;
        <ul>
          <li>Theory of deep (and statistical) learning</li>
          <li>Optimization dynamics of deep networks training</li>
          <li>LLMs engineering design for large scale production</li>
          <li>Neuro-inspired algorithms, backprop-free frameworks</li>
        </ul>
        Also curious about distributed systems and security.
      </div>
    </div>
  );
}

export default Interests;

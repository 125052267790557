import React from "react";
import Header from "../components/Header";

const VerticalSpacing = ({ size }) => {
  const defaultSize = "1rem";

  return (
    <div
      style={{
        height: size || defaultSize,
      }}
    ></div>
  );
};

function Research() {
  return (
    <div className="App">
      <div className="App-content">
        <Header />
        Research i've been involved in;
        <ul>
          <li>
            (Now) Inductive biases in deep learning training dynamics (sgd+wd)
          </li>
          {/* <li>
            (Now) Theory-driven algorithms for networks pruning or compression
          </li> */}
          <li>
            (Now) 0th order optimization techniques (backpropagation-free)
          </li>

          <VerticalSpacing size="1rem" />
          <li>(2023) Artificial curiosity for reinforcement learning agents</li>
          <li>
            (2023) Hessian-based techniques to address catastrophic forgetting
          </li>
          <li>(2023) Regularization in arbitrary predictive coding models</li>

          <VerticalSpacing size="1rem" />
          <li>(2022) Optimal ann-to-snn conversion algorithm (spiking nets)</li>
          <li>
            (2022) Hybrid deep learning approaches for time-series analysis
          </li>
          <li>
            (2022) Matrix factorization for collaborative filtering recsys
          </li>

          <VerticalSpacing size="1rem" />
          <li>(2021) Use of Transformers for news propaganda detection</li>
          <li>(Prior) Undergrad student staffed on many practical projects</li>
        </ul>
        All past projects listed on{" "}
        <a href="https://github.com/andreakiro/projects">
          github.com/andreakiro/projects
        </a>
      </div>
    </div>
  );
}

export default Research;

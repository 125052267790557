import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <main>
      About me
      <ul>
        <li>Currently Research at MIT. Graduated from ETHZ/EPFL (CH)</li>
        <li>I love research and building things. Building products</li>
        <li>Born in Switzerland in year 2000. Half Italian (from Napoli)</li>
        <li>Based in Boston. Previously lived in Zürich, Paris, Moscow</li>
        <li>Worked for IBM Research, Amazon, A. Piguet, Delimobil</li>
      </ul>
      A bit more
      <ul>
        <li>
          <Link to="/interests">Research interests</Link>
        </li>
        <li>
          <Link to="/research">List of past projects</Link>
        </li>
        <li>
          <Link to="/cyberlinks">Contact information</Link>
        </li>
      </ul>
      Last updated on 01-nov-2023.
    </main>
  );
}

export default Home;

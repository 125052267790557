import React from "react";
import { useMediaQuery } from "react-responsive";

function Header() {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <header>
      <h1>/andreapinto</h1>
    </header>
  );
}

export default Header;

import React from "react";
import Header from "../components/Header";
import Home from "../components/Home";

function Root() {
  return (
    <div className="App">
      <div className="App-content">
        <Header />
        <Home />
      </div>
    </div>
  );
}

export default Root;
